import routeEvent from '@/types/routeEvent'
import ShowMarkerSetting from '@/types/ShowMarkerSetting'
import vehicle from '@/types/vehicle'
import VehicleWorkStatus from '../services/vehicle/VehicleWorkStatus'
import { markerType, workStatus } from './const'
import { createHash } from 'crypto'

export const openLink = (url: string, newTab: boolean) => {
  const a = document.createElement('a') as HTMLAnchorElement
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')
  a.href = url
  if (newTab) {
    a.target = '_blank'
  }
  a.click()
  document.body.removeChild(a)
}

export const formatDateTime = (date: Date): string => {
  return formatDate(date) + ' ' + formatTime(date)
}

export const formatDateTimeWithSeconds = (
  date: Date,
  separateChar: string = '/'
): string => {
  return formatDate(date, separateChar) + ' ' + formatTimeWithSeconds(date)
}

export const formatDate = (date: Date, separateChar: string = '/'): string => {
  const d = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear().toString()
  return (
    y +
    separateChar +
    (m <= 9 ? '0' + m : m) +
    separateChar +
    (d <= 9 ? '0' + d : d)
  )
}

export const formatTime = (date: Date): string => {
  const h = date.getHours()
  const mm = date.getMinutes()
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm)
}

export const formatTimeFromSeconds = (
  seconds: number | undefined
): string | undefined => {
  if (seconds === undefined) return undefined
  const h = Math.floor(seconds / 3600)
  const mm = Math.floor((seconds % 3600) / 60)
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm)
}

const formatTimeWithSeconds = (date: Date): string => {
  const h = date.getHours()
  const mm = date.getMinutes()
  const s = date.getSeconds()
  return (
    (h <= 9 ? '0' + h : h) +
    ':' +
    (mm <= 9 ? '0' + mm : mm) +
    ':' +
    (s <= 9 ? '0' + s : s)
  )
}

export const getTimeDifference = (start: Date, end: Date): string => {
  //秒を0にする
  const startDate = new Date(start.getTime())
  startDate.setSeconds(0, 0)
  const endDate = new Date(end.getTime())
  endDate.setSeconds(0, 0)

  const diffInMinutes = (endDate.valueOf() - startDate.valueOf()) / 1000 / 60
  if (diffInMinutes < 0) {
    return ''
  }
  const h = Math.trunc(diffInMinutes / 60)
  const mm = Math.trunc(diffInMinutes - h * 60)
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm)
}

export const convertTimeFormat = (date: string) => {
  if (date === '00:00') {
    return '0時間0分'
  }
  const [hours, minutes] = date.split(':')
  const hoursNum = parseInt(hours)
  const minutesNum = parseInt(minutes)
  return `${hoursNum}時間${minutesNum}分`
}

export const convertShortDeliveryPlanId = (
  delivery_plan_id: string
): string => {
  const len = 7
  return delivery_plan_id.substr(len * -1, len)
}

// '02:04:33'
export const getSecondsByString = (hms: string): number => {
  const arTime = hms.split(':')
  if (arTime.length != 3) {
    return 0
  }

  let valide = true
  for (let i = 0; i < arTime.length; i++) {
    if (!/^-?\d+$/.test(arTime[i])) {
      valide = false
    }
  }

  if (!valide) {
    return 0
  }

  return +arTime[0] * 60 * 60 + +arTime[1] * 60 + +arTime[2]
}

export const addSecondsToDate = (date: Date, seconds: number): Date => {
  const newDate = new Date(date.getTime())
  newDate.setSeconds(date.getSeconds() + seconds)
  return newDate
}

export const roundByNumberOfDigit = (
  num: number,
  numberOfDigit: number
): number => {
  const multiplier = 10 ** numberOfDigit
  return Math.round(num * multiplier) / multiplier
}

export const sortVehicle = (a: vehicle, b: vehicle): number => {
  const statusA = new VehicleWorkStatus(a.status)
  const statusB = new VehicleWorkStatus(b.status)

  if (statusA.isReturn() && statusB.isReturn()) {
    return 1
  }

  if (statusB.isReturn() && !statusA.isReturn()) {
    return -1
  }

  if (statusA.isInService() && !statusB.isInService()) {
    return -1
  }

  if (statusB.isInService() && !statusA.isInService()) {
    return 1
  }

  if (statusA.isInOperation() && !statusB.isInOperation()) {
    return -1
  }

  if (statusB.isInOperation() && !statusA.isInOperation()) {
    return 1
  }

  if (statusA.isInRest() && !statusB.isInRest()) {
    return -1
  }

  if (statusB.isInRest() && !statusA.isInRest()) {
    return 1
  }

  if (statusA.isStop() && !statusB.isStop()) {
    return -1
  }

  if (statusB.isStop() && !statusA.isStop()) {
    return 1
  }

  if (a.registration_number > b.registration_number) {
    return 1
  }
  if (a.registration_number < b.registration_number) {
    return -1
  }

  return 0
}

export const formatArrayDateToArrayYYYYMMDD = (arDates: Array<Date>) => {
  let result: string

  result = formatDate(arDates[0])
  if (arDates[1]) {
    result += ' - ' + formatDate(arDates[1])
  }

  return result
}

export const isSelectableMarker = (
  event: routeEvent,
  showMarkerSetting: ShowMarkerSetting
): markerType | undefined => {
  let marker: markerType
  if (
    event.type == workStatus.WORK_STATUS_REST ||
    event.type == workStatus.WORK_STATUS_BREAK
  ) {
    marker = markerType.REST
  } else {
    marker = markerType.WORK_EVENT
  }
  if (
    (!showMarkerSetting.work_event && marker === markerType.WORK_EVENT) ||
    (!showMarkerSetting.rest && marker === markerType.REST) ||
    event.type === workStatus.WORK_STATUS_IN_SERVICE ||
    event.type.indexOf('_end') > 0
  ) {
    return undefined
  }
  return marker
}

export const getMinDateForSearch = (): Date => {
  let date = new Date()
  date = new Date(date.setMonth(date.getMonth() - 13))
  date = new Date(date.setDate(date.getDate() + 1))
  date.setHours(0, 0, 0, 0)
  return date
}

export const makeHashSha256 = (string: string) => {
  return createHash('sha256').update(string).digest('hex')
}

export const stringDatetoDate = (stringDate: string) => {
  const [dateString, timeString, milisecondString] = stringDate.split(/[ |.]/)

  const [year, month, day] = dateString.split(/[/|-]/).map((str: string) => {
    return Number(str)
  })
  const [hour, minute, second] = timeString.split(':').map((str: string) => {
    return Number(str)
  })

  let milisecond = 0
  try {
    if (milisecondString) milisecond = Number(milisecondString.slice(0, 3))
  } catch (TypeError) {
    console.log('invalid milisecond. ' + stringDate)
  }

  return new Date(year, month - 1, day, hour, minute, second, milisecond)
}
